
import { defineComponent } from 'vue'
import { useRouter } from 'vue-router'
import { lockClosedOutline } from 'ionicons/icons'
import { useAuth } from '@dview/logic/use-auth'
import { LoginStatus } from '@dview/logic/auth/auth.interface'
import { sleep } from '@dview/shared/util/async'
import { useDViewGlobalRouter } from '@dview/core/use-dview-global-router'
import { Capacitor } from '@capacitor/core'

export default defineComponent({
    setup() {
        const router = useRouter()
        const globalRouter = useDViewGlobalRouter()
        const { login } = useAuth()

        async function performLogin() {
            let loginResult: LoginStatus

            if (Capacitor.isNativePlatform()) {
                loginResult = await loginNative()
            } else {
                loginResult = await loginWeb()
            }

            if (loginResult === LoginStatus.Success) {
                if (globalRouter.isAppEntryRouteAvailable()) {
                    const appEntryRoute = globalRouter.popAppEntryRoute()
                    router.replace(appEntryRoute)
                } else {
                    router.replace({ path: '/' })
                }
            } else {
                console.error('Error during login')
                router.replace({ name: 'Error' }) // TODO: We should probably handle a potential login error more gracefully
            }
        }

        async function loginNative() {
            // UX: introduce a fake pause to allow user to see the login information text before redirecting, and avoid "flashing experience"
            await sleep(2000)

            const result = await login()

            // UX: introduce a fake pause to allow user to see the login information upon return from redirect, and avoid "flashing experience"
            await sleep(1000)

            return result
        }

        // web login flow is a bit different with timings, since it destroys and re-creates the app,
        // so make sure all the delay timings are not executed twice.
        async function loginWeb() {
            if (isReturnedFromRedirectContext()) {
                const result = await login()
                await sleep(1000)
                return result
            } else {
                await sleep(2000)
                const result = await login()
                return result
            }
        }

        performLogin()

        return {
            lockClosedOutline
        }
    },
})

function isReturnedFromRedirectContext() {
    return !!window.location.hash
}
